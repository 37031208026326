import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { LiveMessage } from "react-aria-live";

import { closeRightDrawer } from "Reducers/app";

import RightPanel from "Components/RightPanel";
import ServiceOverview from "Containers/ServiceOverview";

const TopBarLayout = styled.div`
  display: flex;
  align-items: center;
`;

const ApplicationName = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
`;

class ServiceInfoPanel extends React.Component {
  constructor(props) {
    super(props);

    this.changeTab = this.changeTab.bind(this);

    this.state = {
      currentTab: "overview"
    };
  }

  changeTab(tabRoute) {
    this.setState({
      currentTab: tabRoute
    });
  }

  render() {
    const { data = { name: "router" } } = this.props;

    return (
      <RightPanel
        open={this.props.open}
        TopBarComponent={
          <TopBarLayout>
            <ApplicationName id="service-right-panel-title">
              {data.name}
            </ApplicationName>
          </TopBarLayout>
        }
      >
        <LiveMessage message={`${data.name}`} aria-live="polite" />
        <ServiceOverview type={data.techno} version={data.version} />
      </RightPanel>
    );
  }
}

ServiceInfoPanel.propTypes = {
  open: PropTypes.bool,
  closeDrawerFct: PropTypes.func,
  data: PropTypes.object
};

const mapStateToProps = state => ({
  open: state.app.get("rightDrawerOpen", true),
  data: state.app.get("rightDrawerData")
});

const mapDispatchToProps = dispatch => ({
  closeDrawerFct: () => dispatch(closeRightDrawer())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceInfoPanel);
