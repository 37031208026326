import React from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import LabeledInfo from "Components/LabeledInfo";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 30px;

  table + a {
    margin-top: 5px;
  }

  a + table {
    margin-top: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  height: 50px;
  flex-shrink: 0;
  margin-bottom: 30px;
  div + div {
    margin-left: 40px;
  }
`;

const ServiceOverview = ({ version, type, intl }) => (
  <Layout>
    <Row>
      <LabeledInfo
        label="Version"
        value={version || intl.formatMessage({ id: "unknown" })}
      />
      <LabeledInfo
        label="Type"
        value={type || intl.formatMessage({ id: "unknown" })}
      />
    </Row>
  </Layout>
);

ServiceOverview.propTypes = {
  version: PropTypes.string,
  type: PropTypes.string,
  intl: PropTypes.object
};

export default injectIntl(ServiceOverview);
