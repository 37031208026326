import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  color: #404041;
  font-size: 22px;
`;

const Label = styled.span`
  text-transform: uppercase;
  color: #b8b8b8;
  font-size: 10px;
  margin-top: 5px;
`;

const LabeledInfo = ({ label, value }) => (
  <Layout>
    <span>{value}</span>
    <Label>{label}</Label>
  </Layout>
);

LabeledInfo.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default LabeledInfo;
