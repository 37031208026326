import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ListGroup from "Components/ListGroup";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: #404041;
  overflow-y: auto;
  margin-left: 40px;
  overflow: visible;
`;

const TopBarLayout = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgb(22, 100, 255);
  padding: 32px;
  border-radius: 3px 3px 0 0;

  img + span {
    margin-left: 5px;
  }
  #modal-close {
    fill: #fff;
  }
`;

const LeftTopBarLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span + div {
    margin-left: 10px;
  }

  h3 {
    margin: 0;
  }
`;

class RightPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onAnimationStart = this.onAnimationStart.bind(this);
    this.onAnimationEnd = this.onAnimationEnd.bind(this);
    this.state = {
      animate: false
    };
  }

  componentDidMount() {
    document.body.addEventListener("animationend", this.onAnimationEnd);
    document.body.addEventListener("webkitAnimationEnd", this.onAnimationEnd);

    document.body.addEventListener("animationstart", this.onAnimationStart);
    document.body.addEventListener(
      "webkitAnimationStart",
      this.onAnimationStart
    );
  }

  componentWillUnmount() {
    document.body.removeEventListener("animationend", this.onAnimationEnd);
    document.body.removeEventListener(
      "webkitAnimationEnd",
      this.onAnimationEnd
    );

    document.body.removeEventListener("animationstart", this.onAnimationStart);
    document.body.removeEventListener(
      "webkitAnimationStart",
      this.onAnimationStart
    );
  }

  onAnimationEnd(event) {
    if (event.animationName === "slideInEnvironementDrawerLeft") {
      this.setState(() => ({ animate: false }));
    } else if (event.animationName === "slideOutEnvironementDrawerRight") {
      event.target.style.width = "0px";
      event.target.style.height = "0px";
    } else if (event.animationName === "slideOutEnvironementDrawerLeft") {
      this.setState(() => ({ animate: true }));
    }
  }

  onAnimationStart(event) {
    if (event.animationName === "slideInEnvironementDrawerRight") {
      event.target.style.width = "100%";
      event.target.style.height = "100%";
    }
  }

  render() {
    const { open, TopBarComponent = false } = this.props;
    const animate = this.state.animate;

    let className = "";
    if (open === true && animate) {
      className = "slideInRightAnimation";
    } else if (open === false) {
      className = "slideOutRightAnimation";
    }

    return (
      <Layout
        className={`services-right-panel${className ? " " + className : ""}`}
      >
        <ListGroup>
          <TopBarLayout aria-labelledby="service-name">
            <LeftTopBarLayout>
              <h3 id="service-name">{TopBarComponent}</h3>
            </LeftTopBarLayout>
          </TopBarLayout>
          {this.props.children}
        </ListGroup>
      </Layout>
    );
  }
}

RightPanel.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  TopBarComponent: PropTypes.node,
  closeDrawerFct: PropTypes.func
};

export default RightPanel;
