import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { LiveMessage } from "react-aria-live";

import ServicesDisplay from "Containers/ServicesDisplay";
import ServiceInfoPanel from "Containers/ServiceInfoPanel";
import ListGroup from "Components/ListGroup";
import Heading2 from "Components/styleguide/Heading2";

const EnvironmentServicesLayout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding-top: 50px;
  .service-tree-wrapper {
    padding: 30px;
  }

  @media (max-device-width: 900px), (max-width: 900px) {
    display: flex;
    flex-direction: column-reverse;

    & > div {
      width: 100%;
      margin: 0px;
    }
  }
`;

class EnvironmentServices extends React.Component {
  render() {
    const { projectId, environmentId, organizationId } = this.props.params;

    return (
      <EnvironmentServicesLayout>
        <LiveMessage message={`${environmentId} services`} aria-live="polite" />
        <ListGroup
          className="service-tree-wrapper"
          aria-labelledby="services-heading"
        >
          <Heading2 id="services-heading">
            <FormattedMessage id="services" />
          </Heading2>
          <ServicesDisplay
            projectId={projectId}
            environmentId={environmentId}
            organizationId={organizationId}
            height="450px"
            minHeight="400px"
          />
        </ListGroup>
        <ServiceInfoPanel
          projectId={projectId}
          environmentId={environmentId}
          organizationId={organizationId}
        />
      </EnvironmentServicesLayout>
    );
  }
}

EnvironmentServices.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string,
    environmentId: PropTypes.string,
    organizationId: PropTypes.string
  })
};

export default EnvironmentServices;
